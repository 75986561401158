// @flow
import React, { useState } from 'react';
import * as text from 'constants/strings';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch, useSelector } from 'react-redux';
import {
    addAlert,
    selectShops,
    setIsLoading,
} from 'context/features/global/globalSlice';
import moment from 'moment';
import {
    Collapse,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    colors,
    Select,
    MenuItem,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { findShopById, formatName, formatNumber } from 'helpers/global';
import { StyledTableCell, CustomTypography } from 'styles/components';
import styled from 'styled-components/macro';

export const StyledRow = styled(TableRow)`
    ${({ haspaid }) =>
        haspaid !== undefined &&
        `background-color: ${haspaid ? colors.green[50] : colors.red[50]};`}
    background-color: ${({ isfilterrow }) => isfilterrow && colors.lime[50]};
`;

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    container: {
        marginBottom: '10px',
    },
});

const useTableStyles = makeStyles({
    head: {
        textTransform: 'uppercase',
        backgroundColor: '#f5f5f5',
        textAlign: 'left',
    },
});

const Row = ({
    products,
    username,
    setResult,
    isFilterRow,
}: {
    products: Array<any>,
    username: string,
    setResult: Function,
    isFilterRow: boolean,
}) => {
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShops(state));

    const handleChangeStatus = async (id, value) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('put', `/exchange/${id}/paid-status`, {
                hasPaid: value,
            });
            setResult((prev) => {
                const mProducts = prev[username].products.map((item) => {
                    if (item.exchangeId === id) {
                        return { ...item, hasPaid: value };
                    }

                    return item;
                });

                return { ...prev, [username]: { products: mProducts } };
            });
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <>
            <StyledRow
                isfilterrow={isFilterRow ? 1 : 0}
                className={classes.root}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {isFilterRow
                        ? text.filtersRow[username]
                        : formatName(username)}
                </TableCell>
            </StyledRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer
                            component={Paper}
                            className={classes.container}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            {text.CODE}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {text.BRAND}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {text.COLOR}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {text.MODEL}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {text.NORMAL_PRICE}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {text.PROMOTIONAL_PRICE}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {text.SHOP}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {text.SIZE}
                                        </StyledTableCell>
                                        {!isFilterRow && (
                                            <StyledTableCell>
                                                {text.STATUS}
                                            </StyledTableCell>
                                        )}
                                        <StyledTableCell>
                                            {text.DATE}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((row) => {
                                        const styleProps = {};
                                        if (!isFilterRow) {
                                            styleProps.haspaid = row.hasPaid
                                                ? 1
                                                : 0;
                                        }

                                        return (
                                            <StyledRow
                                                {...styleProps}
                                                key={row.exchangeId}
                                            >
                                                <TableCell align="center">
                                                    {row.productCode}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.productBrand}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.productColor}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.productType}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {formatNumber(
                                                        row.productPrimaryPrice
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {formatNumber(
                                                        row.productPromotionalPrice
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <CustomTypography
                                                        component="span"
                                                        fontcolor={
                                                            findShopById(
                                                                shops,
                                                                row.shopId
                                                            ).color
                                                        }
                                                    >
                                                        {
                                                            findShopById(
                                                                shops,
                                                                row.shopId
                                                            ).name
                                                        }
                                                    </CustomTypography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.size}
                                                </TableCell>
                                                {!isFilterRow && (
                                                    <TableCell align="center">
                                                        <Select
                                                            value={row.hasPaid}
                                                            onChange={(e) =>
                                                                handleChangeStatus(
                                                                    row.exchangeId,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem value>
                                                                Zapłacone
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={false}
                                                            >
                                                                Niezapłacone
                                                            </MenuItem>
                                                        </Select>
                                                    </TableCell>
                                                )}
                                                <TableCell align="center">
                                                    {moment(row.date).format(
                                                        'YYYY-MM-DD HH:mm:ss'
                                                    )}
                                                </TableCell>
                                            </StyledRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

type Props = { result: Array<[string, any]>, setResult: Function };

const AccordionTable = ({ result, setResult }: Props) => {
    const classes = useTableStyles();

    return (
        result.length > 0 && (
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{ width: '10px' }}
                                className={classes.head}
                            />
                            <TableCell className={classes.head} align="left">
                                Imię Nazwisko
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map(([username, { products }]) => (
                            <Row
                                setResult={setResult}
                                key={username}
                                products={products}
                                username={username}
                                isFilterRow={[
                                    '_isFree',
                                    '_hasComplaint',
                                ].includes(username)}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    );
};

export default AccordionTable;
