// @flow
import React from 'react';
import {
    Checkbox,
    FormControlLabel,
    makeStyles,
    Select,
    MenuItem,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    TimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles((theme) => ({
    selectPeriod: {
        marginTop: '10px',
        marginLeft: '10px',
        height: '32px',
    },
    selectCalendar: {
        marginTop: '10px',
        marginRight: '5px',
    },
    cardHeaderOptions: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    datePickersContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    rangeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: '16px',
    },
    rangeContainerItem: {
        whiteSpace: 'nowrap',
        padding: '5px 5px 5px 0px',
    },
    timePickersContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    hoursContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    selectTime: {
        width: '50px',
        margin: '0 10px',
    },
    timeSelectPrefix: {
        display: 'flex',
        alignItems: 'center',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    fontNormal: {
        fontWeight: 'normal',
    },
}));

const SalesDetailFilters = ({
    filters,
    setFilters,
    showShopsSelect = true,
}) => {
    const classes = useStyles();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));

    const handleRangeCheckboxOnChange = (isChecked) => {
        if (isChecked && filters.dateTo > filters.dateFrom) {
            setFilters({
                ...filters,
                rangeCheckbox: isChecked,
            });
        } else {
            setFilters({
                ...filters,
                rangeCheckbox: isChecked,
                dateTo: cloneDeep(filters.dateFrom),
            });
        }
    };

    const handleHoursCheckboxOnChange = (state) => {
        setFilters({
            ...filters,
            hoursCheckbox: state,
        });
    };

    const handleSelectDateFromChange = (event) => {
        if (filters.rangeCheckbox) {
            setFilters({
                ...filters,
                dateFrom: event,
            });
        } else {
            setFilters({
                ...filters,
                dateFrom: event,
                dateTo: event,
            });
        }
    };

    const handleSelectDateToChange = (event) => {
        setFilters({
            ...filters,
            dateTo: event,
        });
    };

    const handleDatePeriodChange = (value) => {
        let from = moment();

        switch (value) {
            case 'lastYear':
                from = from.startOf('year');
                break;
            case 'lastMonth':
                from = from.startOf('month');
                break;
            case 'lastWeek':
                from = from.subtract(7, 'days');
                break;
            default:
                break;
        }

        setFilters({
            ...filters,
            dateFrom: from,
            dateTo: moment(),
            period: value,
        });
    };

    const handleSelectShopChange = (value) => {
        setFilters({
            ...filters,
            shopId: value,
        });
    };

    const handleSelectTimeFromChange = (event) => {
        setFilters({
            ...filters,
            timeFrom: event,
        });
    };

    const handleSelectTimeToChange = (event) => {
        setFilters({
            ...filters,
            timeTo: event,
        });
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.cardHeaderOptions}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.rangeCheckbox}
                            onChange={(e) =>
                                handleRangeCheckboxOnChange(e.target.checked)
                            }
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Zakres"
                />
                <div className={classes.datePickersContainer}>
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format="YYYY-MM-DD"
                        margin="normal"
                        value={filters.dateFrom}
                        onChange={(event) => {
                            handleSelectDateFromChange(event);
                        }}
                        className={classes.selectCalendar}
                    />
                    {filters.rangeCheckbox && (
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            format="YYYY-MM-DD"
                            margin="normal"
                            value={filters.dateTo}
                            onChange={(event) => {
                                handleSelectDateToChange(event);
                            }}
                            className={classes.selectCalendar}
                        />
                    )}
                </div>

                <Select
                    className={classes.selectPeriod}
                    value={filters.period}
                    onChange={(e) => {
                        handleDatePeriodChange(e.target.value);
                    }}
                >
                    <MenuItem value="today">Dzisiaj</MenuItem>
                    <MenuItem value="lastWeek">Ostatnie 7 dni</MenuItem>
                    <MenuItem value="lastMonth">Ostatni miesiąc</MenuItem>
                    <MenuItem value="lastYear">Ostatni rok</MenuItem>
                </Select>
                {showShopsSelect && (
                    <Select
                        displayEmpty
                        className={classes.selectPeriod}
                        value={filters.shopId}
                        onChange={(e) => {
                            handleSelectShopChange(e.target.value);
                        }}
                    >
                        <MenuItem value="">Wszystko</MenuItem>
                        {shops.map((shop) => (
                            <MenuItem key={shop.id} value={shop.id}>
                                {shop.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </div>
            <div className={classes.hoursContainer}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.hoursCheckbox}
                            onChange={(e) =>
                                handleHoursCheckboxOnChange(e.target.checked)
                            }
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Godziny"
                />
                {filters.hoursCheckbox && (
                    <div className={classes.timePickersContainer}>
                        <div className={classes.timeSelectPrefix}>Od</div>
                        <TimePicker
                            ampm={false}
                            views={['hours']}
                            format="HH"
                            value={filters.timeFrom}
                            onChange={(event) => {
                                handleSelectTimeFromChange(event);
                            }}
                            className={classes.selectTime}
                        />
                        <div className={classes.timeSelectPrefix}>Do</div>
                        <TimePicker
                            ampm={false}
                            views={['hours']}
                            format="HH"
                            value={filters.timeTo}
                            onChange={(event) => {
                                handleSelectTimeToChange(event);
                            }}
                            className={classes.selectTime}
                        />
                    </div>
                )}
            </div>
            <div className={classes.rangeContainer}>
                <div className={classes.rangeContainerItem}>
                    {' '}
                    Wyświetlany zakres :{' '}
                </div>
                <div
                    className={`${classes.rangeContainerItem} ${classes.fontBold}`}
                >
                    {' '}
                    {filters.dateFrom.format('YYYY-MM-DD')}
                    {filters.hoursCheckbox && (
                        <span className={classes.fontNormal}>
                            :{filters.timeFrom.format('HH')}
                        </span>
                    )}{' '}
                </div>
                <div className={classes.rangeContainerItem}> - </div>
                <div
                    className={`${classes.rangeContainerItem} ${classes.fontBold}`}
                >
                    {' '}
                    {filters.rangeCheckbox
                        ? filters.dateTo.format('YYYY-MM-DD')
                        : filters.dateTo.format('YYYY-MM-DD')}
                    {filters.hoursCheckbox && (
                        <span className={classes.fontNormal}>
                            :{filters.timeTo.format('HH')}
                        </span>
                    )}{' '}
                </div>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default SalesDetailFilters;
