// @flow
import React, { useState, useEffect } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { Container, Grid } from '@material-ui/core';
import SummaryBlock from 'components/SummaryBlock';
import ShopsRanking from 'components/ShopsRanking';
import SalesDetail from 'components/SalesDetail';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import SalesTimeDetail from 'components/SalesTimeDetail';
import useIsGranted from 'hooks/useIsGranted';

const Reports = () => {
    const [totalSales, setTotalSales] = useState({ current: 0, prev: 0 });
    const [totalReleases, setTotalReleases] = useState({ current: 0, prev: 0 });
    const [totalAddition, setTotalAddition] = useState({ current: 0, prev: 0 });
    const dispatch = useDispatch();
    const isGranted = useIsGranted();
    const isPermission = isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE]);
    const isAdmin = isGranted.hasOneOfRoles.execute([text.ROLE_ADMIN_CODE]);

    const getReport = async (url, setData) => {
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

        const startPrevOfMonth = moment()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD');
        const endPrevOfMonth = moment()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD');

        dispatch(setIsLoading(true));
        try {
            const responseCurrent = await sendAuthAjax(
                'get',
                `${url}?dateFrom=${startOfMonth}&dateTo=${endOfMonth}`
            );
            const responsePrev = await sendAuthAjax(
                'get',
                `${url}?dateFrom=${startPrevOfMonth}&dateTo=${endPrevOfMonth}`
            );
            setData({
                current: responseCurrent.data.result || 0,
                prev: responsePrev.data.result || 0,
            });
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getReport('/reports/total', setTotalSales);
        getReport('/reports/release', setTotalReleases);
        getReport('/reports/products', setTotalAddition);
    }, []);

    return (
        <ViewContentCard title={text.REPORTS}>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    {isPermission && (
                        <Grid item lg={4} xs={12}>
                            <SummaryBlock
                                value={totalSales.current}
                                prevValue={totalSales.prev}
                                title="Sprzedaż łączna"
                                prefix={isAdmin ? "zł" : "szt"}
                            />
                        </Grid>
                    )}

                    {isPermission && (
                        <Grid item lg={4} xs={12}>
                            <SummaryBlock
                                value={totalReleases.current}
                                prevValue={totalReleases.prev}
                                title="Ilość wydań"
                            />
                        </Grid>
                    )}
                    {isPermission && (
                        <Grid item lg={4} xs={12}>
                            <SummaryBlock
                                value={totalAddition.current}
                                prevValue={totalAddition.prev}
                                title="Ilość dodań"
                            />
                        </Grid>
                    )}
                    {isPermission && (
                        <Grid item lg={6} md={12} xs={12}>
                            <SalesDetail />
                        </Grid>
                    )}
                    {isPermission && (
                        <Grid item lg={6} md={12} xs={12}>
                            <SalesTimeDetail />
                        </Grid>
                    )}
                    <Grid item lg={3} md={6} xs={12}>
                        <ShopsRanking
                            title="Ranking dzienny"
                            dateTo={moment().format('YYYY-MM-DD')}
                            dateFrom={moment().format('YYYY-MM-DD')}
                        />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <ShopsRanking
                            limit={!isPermission && 3}
                            title="Ranking miesięczny"
                            dateTo={moment().format('YYYY-MM-DD')}
                            dateFrom={moment()
                                .startOf('month')
                                .format('YYYY-MM-DD')}
                        />
                    </Grid>
                </Grid>
            </Container>
        </ViewContentCard>
    );
};

export default Reports;
