// table
export const BRAND = 'marka';
export const CODE = 'kod';
export const MODEL = 'model';
export const COLOR = 'kolor';
export const TYPE = 'typ';
export const NORMAL_PRICE = 'cena normalna';
export const PROMOTIONAL_PRICE = 'cena promocyjna';
export const PRODUCT_OF_THE_DAY_PROMOTIONAL_PRICE =
    'Cena w promocji Produkt Dnia';
export const ACTUAL_PRICE = 'cena aktualna';
export const EURO = 'euro';
export const FROM_SHOP = 'Ze sklepu';
export const TO_SHOP = 'Do sklepu';
export const ADD = 'Dodaj';
export const EDIT = 'Edytuj';
export const REMOVE = 'usuń';
export const HOUR = 'godzina';
export const DISCOUNT = 'rabat';
export const PAYMENT = 'płatność';
export const PRICE = 'cena';
export const SIZE = 'rozmiar';
export const QUANTITY = 'ilość';
export const DATE = 'data';
export const DATE_FROM = 'Data od';
export const DATE_TO = 'Data do';
export const CREATION_DATE = 'Data utworzenia';
export const WHO = 'komu';
export const INFO = 'info';
export const SUM = 'suma';
export const SHOP_STATE = 'stan magazynowy';
export const ACTION = 'Działanie';
export const NAME = 'Nazwa';
export const CREATE_DATE = 'Data utworzenia';
export const CREATE = 'Utwórz';
export const CREATE_FOR_MONTH = 'Utwórz dla wybranego miesiąca';

export const DATE_OF_ISSUE = 'Data wystawienia';
export const NUMBER = 'Numer';
export const NOTE_NUMBER = 'Numer noty korygującej';
export const INVOICE_NUMBER = 'Numer faktury korygowanej';
export const DATE_OF_PAYMENT = 'Data zapłaty';
export const DATE_OF_SALE = 'Data sprzedaży';
export const PAYMENT_METHOD = 'Metoda płatność';
export const STATUS = 'Status';
export const CURRENCY = 'Waluta';
export const AMOUNT = 'Wartość';
export const CUSTOMER = 'Nabywca';
export const COMPANY_NAME = 'Nazwa firmy';
export const BEST_SELLING_SIZE = 'Najlepiej sprzedający się rozmiar';
export const TOTAL_PLN = 'Łącznie zł';
export const TURNOVER_ON_INSPECTION_DAY = 'Obrót w dniu kontroli';
export const RATING = 'Ocena';
export const AVERAGE_RATING = 'Średnia z ocen';

// menuList
export const SALE = 'Sprzedaż';
export const FIND_SHOES = 'Znajdź obuwie';
export const ATTENDANCE_LIST = 'Lista obecności';
export const LABELS = 'Etykiety';
export const TRANSFER_OF_SHOES_TO = 'Przerzut obuwia do';
export const SALES_PREVIEW = 'Podgląd sprzedaży';
export const EXCHANGE_PREVIEW = 'Podgląd wymiany';
export const SHOE_REPLACEMENT = 'Wymiana obuwia';
export const SHOE_RELEASE = 'Wydanie obuwia';
export const COMPLAINTS = 'Reklamacje';
export const PRICE_CHANGE = 'Zmiana ceny';
export const MESSAGES = 'Komunikaty';
export const ADDING_MESSAGES = 'Dodanie komunikatów';
export const HISTORY_MESSAGES = 'Historia komunikatów';
export const ADDING_SHOES = 'Dodanie obuwia';
export const EDITING_SHOES = 'Edycja obuwia';
export const ADDING_SIZES = 'Dodanie rozmiarów';
export const SHORTAGES = 'Braki';
export const DICTIONARIES = 'Słowniki';
export const ADDING_SHORTAGES = 'Dodanie braków';
export const EXHIBITION = 'Wystawa';
export const ERRORS = 'Błędy';
export const BIG_TRASFER_WITH = 'Duże przerzuty z';
export const GRAPHIC = 'Grafik';
export const WORKERS = 'Pracownicy';
export const APP_SETTINGS = 'Ustawienia';
export const PRODUCT_OF_THE_DAY = 'Produkt dnia';
export const APP_SHOPER_CONFIG = 'Shoper - konfiguracja';
export const LOGOUT = 'Wyloguj';
export const CORRECTING_ERRORS = 'Poprawianie błędów';
export const FIRST_NAME = 'Imię';
export const LAST_NAME = 'Nazwisko';
export const USER_NAME = 'Login';
export const SHOP = 'Sklep';
export const ROLE = 'Rola';
export const ADDITION = 'Dołożenie';
export const INVOICES = 'Faktury';
export const CORRECTIVE_NOTES = 'Noty korygujące';
export const WAREHOUSE = 'Magazyn';
export const ADDING_INVOICE = 'Wystaw fakturę';
export const EDIT_INVOICE = 'Edytuj fakturę';
export const ADDING_COMPLAINT = 'Dodaj Reklamacje';
export const EDIT_COMPLAINT = 'Edytuj reklamacje';
export const ADDING_WORKER = 'Dodaj pracownika';
export const EDIT_WORKER = 'Edytuj pracownika';
export const REPORTS = 'Raporty';
export const LOGS = 'Logi';
export const DESCRIPTION = 'Opis';
export const STATEMENT_SHOP = 'Zestawienie sprzedaży z poszczególnych sklepów';
export const STATEMENT_ADDITION = 'Podgląd dodań';
export const STATEMENT_TOTAL = 'Całkowite zestawienie sprzedaży';
export const STATEMENT_EXCHANGE = 'Obuwie wydane dla pracowników';
export const STATEMENT_PRODUCT = 'Historia poszczególnego modelu';
export const TIMESHEET_MONTHLY_SUMMARIES = 'Miesięczne podsumowania Grafiku - automatycznie wygenerowane na koniec miesiąca';
export const THRESHOLD = 'Progi';
export const CHECKLIST = 'Checklista';

export const SALE_OF_BOOTS = 'Sprzedaż obuwia';
export const SALE_OF_SOCKS_AND_INSOLES = 'Sprzedaż akcesoriów';

export const APP_SHOPER_CONFIG_VARIANTS_MAPPING = 'Powiązanie wariantów';
export const APP_SHOPER_CONFIG_CONNECTION = 'Ustawienia połączenia';
export const SHOPER_VARIANT_ID = 'ID wariantu w Shoper';

export const ROLE_ADMIN = 'Administrator';
export const ROLE_MANAGER = 'Menedżer';
export const ROLE_WAREHOUSE_WORKER = 'Magazynier';
export const ROLE_EMPLOYEE = 'Sprzedawca';
export const ROLE_DIRECTOR = 'Kierownik';

export const ROLE_ADMIN_CODE = 'ROLE_ADMIN';
export const ROLE_MANAGER_CODE = 'ROLE_MANAGER';
export const ROLE_WAREHOUSE_WORKER_CODE = 'ROLE_WAREHOUSE_WORKER';
export const ROLE_EMPLOYEE_CODE = 'ROLE_EMPLOYEE';
export const ROLE_DIRECTOR_CODE = 'ROLE_DIRECTOR';
export const NOBODY_CODE = 'NOBODY';

export const IS_IT_ECOMMERCE = 'Czy sprzedaż internetowa?';
export const CHECK_IF_STOCKS_CHANGED_IN_MEANTIME =
    'Sprawdzaj czy stany magazynowe zmieniły się w międzyczasie';

export const CURRENT_PRODUCTS_OF_THE_DAY = 'Aktualne Produkty dnia';
export const SCHEDULED_PRODUCTS_OF_THE_DAY = 'Zaplanowane produkty dnia';

export const NON_TRADING_SUNDAY_TAG = 'nnh';
export const HOLIDAY_TAG = 's';
export const GENERATE_TIMESHEET_MONTHLY_SUMMARY = 'Wygeneruj podsumowanie Grafiku';
export const GENETARE_MONTHLY_SUMMARY = 'Generuj miesięczne podsumowanie';
export const GENETARE_PREVIOUS_MONTH_SUMMARY = 'Wygeneruj podsumowanie za poprzedni miesiąc';
export const SET_THRESHOLDS = 'Ustaw progi';

export const dictionary = {
    sizeStandards: 'Standardy rozmiarów',
    needs: 'Potrzeby',
    graphicTypes: 'Typy dla grafika',
};

export const filtersRow = {
    _hasComplaint: 'Reklamacja wydana',
    _isFree: 'Gratis klient',
};

export const shopName = {
    PORAJ: 'Poraj',
    SUKIENNICE: 'Sukiennice',
    KARPACZ: 'Karpacz',
    WISLA: 'Wisla',
    RYNEK: 'Rynek',
    KRUPOWKI: 'Krupówki',
    PRZEJSCIE: 'Przejście',
    ECOMMERCE: 'Sprzedaż Internetowa',
};

export const shopShortName = {
    PORAJ: 'p',
    SUKIENNICE: 's',
    RYNEK: 'r',
    KARPACZ: 'k',
    KARPACZ2: 'k2',
    WISLA: 'w',
    KRUPOWKI: 'kr',
    PRZEJSCIE: 'prz',
    ECOMMERCE: 'si',
    MAGAZYN: 'm',
    RYNEK: 'r',
};

//other types
export const OFFICE = 'BIURO';
export const TRAINING = 'SZKOLENIE';
