// @flow
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
    colors,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    setIsLoading,
    addAlert,
    selectShopsWithoutWarehouse,
} from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import SalesDetailFilters from './SalesDetailFilters';

const useStyles = makeStyles(() => ({
    cardHeader: {
        display: 'block',
    },
}));

const SalesDetail = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const [data, setData] = useState(null);
    const [filters, setFilters] = useState({
        rangeCheckbox: false,
        dateFrom: moment(),
        dateTo: moment(),
        hoursCheckbox: false,
        timeFrom: moment().hours(8),
        timeTo: moment().hours(12),
        period: 'today',
    });

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                    },
                    gridLines: {
                        display: true,
                        drawBorder: true,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider,
                    },
                },
            ],
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary,
        },
    };

    const prepareBarData = (responseData) => {
        const barData = {
            datasets: [
                {
                    maxBarThickness: 10,
                    categoryPercentage: 0.5,
                    barThickness: 12,
                    barPercentage: 0.5,
                    backgroundColor: colors.indigo[500],
                    data: [],
                    label: 'Wartość',
                },
            ],
            labels: [],
        };

        shops.forEach((shop) => {
            barData.labels.push(shop.name);
            const shopResult = responseData.find(
                (val) => val.shopId === shop.id
            );
            if (shopResult !== undefined) {
                barData.datasets[0].data.push(shopResult.result);
            } else {
                barData.datasets[0].data.push(0);
            }
        });

        return barData;
    };

    const getSalesShops = async () => {
        if (filters.rangeCheckbox && filters.dateTo < filters.dateFrom) {
            dispatch(
                addAlert({
                    content: 'Data "do" nie może być mniejsza od daty "od"',
                    type: 'error',
                })
            );
            return;
        }

        if (filters.hoursCheckbox && filters.timeTo < filters.timeFrom) {
            dispatch(
                addAlert({
                    content:
                        'Godzina "do" nie może być mniejsza od godziny "od"',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));

        const searchParams = new URLSearchParams();

        searchParams.append('dateFrom', filters.dateFrom.format('YYYY-MM-DD'));
        searchParams.append('dateTo', filters.dateTo.format('YYYY-MM-DD'));

        if (filters.hoursCheckbox) {
            searchParams.append('timeFrom', filters.timeFrom.format('H'));
            searchParams.append('timeTo', filters.timeTo.format('H'));
        }

        try {
            const responseCurrent = await sendAuthAjax(
                'get',
                '/reports/shops',
                undefined,
                searchParams
            );
            setData(prepareBarData(responseCurrent.data));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getSalesShops();
    }, [filters]);

    return (
        <Card className={clsx(classes.root)}>
            <CardHeader
                style={{ flexWrap: 'nowrap' }}
                className={classes.cardHeader}
                action={
                    <SalesDetailFilters
                        filters={filters}
                        setFilters={setFilters}
                        showShopsSelect={false}
                    />
                }
                title="Szczegółowe zestawienie sprzedaży"
            />
            <Divider />
            <CardContent>
                <Box height={400} position="relative">
                    {data && <Bar data={data} options={options} />}
                </Box>
            </CardContent>
        </Card>
    );
};

export default SalesDetail;
