// @flow
import React, { useState } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReleaseTableRow from 'components/ReleaseTableRow';
import { selectUser } from 'context/features/user/userSlice';
import { validateNumberValue } from 'helpers/global';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { clearSearchParams } from 'context/features/sale/saleSlice';

const ReleaseShoes = () => {
    const [result, setResult] = useState([]);
    const dispatch = useDispatch();
    const { releaseId } = useParams();
    const user = useSelector((state) => selectUser(state));

    const handleClick = async (productId: number, data: any) => {
        const { sizeTo, info } = data;
        if (!validateNumberValue(sizeTo)) {
            dispatch(
                addAlert({ content: 'Wybierz poprawny rozmiar', type: 'error' })
            );
            return;
        }
        if (!info) {
            dispatch(
                addAlert({
                    content: 'Wypełnij pole z informacją',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', `/exchange/product-employee-release`, {
                sizeTo,
                info,
                productId,
                shopId: user.shopId,
            });
            dispatch(clearSearchParams());
            setResult([]);
            dispatch(addAlert({ content: 'Wydano obuwie', type: 'success' }));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const headers = releaseId
        ? [
              text.CODE,
              text.BRAND,
              text.COLOR,
              text.MODEL,
              text.NORMAL_PRICE,
              text.PROMOTIONAL_PRICE,
              text.SIZE,
              '',
          ]
        : [
              text.CODE,
              text.BRAND,
              text.COLOR,
              text.MODEL,
              text.NORMAL_PRICE,
              text.PROMOTIONAL_PRICE,
              text.INFO,
              text.SIZE,
              '',
          ];

    return (
        <ViewContentCard title={text.SHOE_RELEASE}>
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={headers}
                    rowRender={(row) => (
                        <ReleaseTableRow
                            key={row.id}
                            row={row}
                            handleClick={handleClick}
                        />
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default ReleaseShoes;
