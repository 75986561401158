export const SALE_OF_BOOTS = 'SALE_OF_BOOTS';
export const SALE_OF_SOCKS_AND_INSOLES = 'SALE_OF_SOCKS_AND_INSOLES';

export const PLN = 'PLN';
export const EUR = 'EUR';

export const currencySymbol = {
    PLN: 'zł',
    EUR: '€',
};

export const invoiceStatus = {
    paid: 'Opłacona',
    notPaid: 'Nieopłacona',
    partiallyPaid: 'Częściowo opłacona',
};

export const productType = {
    shoe: 'Obuwie',
    shoeAccessories: 'Akcesoria do obuwia',
};

export const paymentMethod = {
    transfer: 'Przelew',
    cash: 'Gotówka',
    creditCard: 'Karta płatnicza',
    cash_on_delivery: 'Pobranie',
};

export const complaintStatus = {
    notExamined: 'Nierozpatrzono',
    shoeRepair: 'Naprawa obuwia',
    shoeExchange: 'Wymiana obuwia',
    refund: 'Zwrot pieniędzy',
    notRecognized: 'Reklamacja nie uznana',
};

export const kindOfNotification = {
    byPhone: 'Telefonicznie',
    sms: 'SMS',
    mail: 'E-mail',
    personally: 'Osobiscie',
    written: 'Pisemnie',
    alternative: 'Inny',
};

export const arabicNumbersMonths = {
    1: '01',
    2: '02',
    3: '03',
    4: '04',
    5: '05',
    6: '06',
    7: '07',
    8: '08',
    9: '09',
    10: '10',
    11: '11',
    12: '12',
}

export const romanianNumbersMonths = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
    5: 'V',
    6: 'VI',
    7: 'VII',
    8: 'VIII',
    9: 'IX',
    10: 'X',
    11: 'XI',
    12: 'XII',
}

export const shopsAllowedForShopFromSelectInSaleTable = [
    'Karpacz',
    'Karpacz 2'
]

export const productOfTheDayDialogModes = {
    add: 'add',
    edit: 'edit'
}

export const statementSearchMode = {
    month: 'Miesiąc',
    period: 'Zakres Dat'
}

export const localicationToShopMap = {
    'bialka': [
        'Białka'
    ]
}

export const statementShopOrderByOptions = {
    sales: 'sales',
    totalPLN: 'totalPLN'
}

export const productTypeCode = {
    shoe: 'shoe',
    shoeAccessories: 'shoeAccessories'
}
