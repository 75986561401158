// @flow
import React, { useState, memo } from 'react';
import {
    Button,
    TextField,
    TableRow,
    TableCell,
    Select,
    MenuItem,
    Box,
    makeStyles,
} from '@material-ui/core';
import {
    selectShopsWithoutWarehouse,
    selectEuroRate,
} from 'context/features/global/globalSlice';
import { useSelector } from 'react-redux';
import { PLN, EUR, currencySymbol, shopsAllowedForShopFromSelectInSaleTable } from 'constants/global';
import green from '@material-ui/core/colors/green';
import { convertToEuro } from 'helpers/global';
import { selectUser } from 'context/features/user/userSlice';
import { isEqual } from 'lodash';
import type { Order, Shoe } from "../helpers/interface";
import clsx from 'clsx';

type Props = {
    rowData: Array<string>,
    currencyVisible: boolean,
    shopFromVisible: boolean,
    handleClickRow: (data: Order, productData: Shoe) => React.Node,
};

const useStyles = makeStyles(() => ({
    discount: {
        width: '65px',
        marginRight: '10px',
    },
    featured: {
        color: green.A400,
    },
    shopsSelect: {
        minWidth: '115px',
    },
}));

const SaleTableRow = ({
    rowData,
    viewType,
    currencyVisible = true,
    shopFromVisible = false,
    handleClickRow,
}: Props) => {
    const classes = useStyles();
    const euroRate = useSelector((state) => selectEuroRate(state));
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const user = useSelector((state) => selectUser(state));
    const [data, setData] = useState({
        primary_price: rowData.primary_price,
        promotional_price: rowData.promotional_price,
        currency: PLN,
        discount: {
            percent: '',
            currValue: '',
        },
        euro: null,
        size: '',
        quantity: '',
        shopFromId: user.shopId || null,
    });

    const getDiscountPrice = (priceToBeChanged, value, type) => {
        let price = rowData[priceToBeChanged];

        if (value === '') {
            return price;
        }

        if (type === 'percent') {
            price -= price * (value / 100);
        } else {
            price -= value;
        }

        return Math.round(Number.isInteger(price) ? price : price.toFixed(0));
    };

    const handleChangeDiscount = (value: number, type: string) => {
        const priceToBeChanged: string =
            rowData.promotional_price > 0
                ? 'promotional_price'
                : 'primary_price';

        const price = getDiscountPrice(priceToBeChanged, value, type);
        const euro =
            data.currency === EUR ? convertToEuro(price, euroRate, true) : null;

        setData({
            ...data,
            [priceToBeChanged]: price,
            euro,
            discount: {
                ...data.discount,
                [type]: value,
            },
        });
    };

    const handleChangeCurrency = (value: string) => {
        setData({
            ...data,
            euro:
                value === EUR
                    ? convertToEuro(
                          rowData.promotional_price > 0
                              ? rowData.promotional_price
                              : rowData.primary_price,
                          euroRate,
                          true
                      )
                    : null,
            primary_price: rowData.primary_price,
            promotional_price: rowData.promotional_price,
            currency: value,
            discount: {
                percent: '',
                currValue: '',
            },
        });
    };

    const handleSubmitEnter = (e, data, rowData) => {
        if (e.key === 'Enter') {
            handleClickRow(data, rowData);
        }
    };

    const shopsInShopFromSelect = shops.filter((shop: Shop) => shopsAllowedForShopFromSelectInSaleTable.includes(shop.name));

    return (
        <TableRow hover>
            <TableCell align="center">{rowData.code}</TableCell>
            <TableCell align="center">{rowData.brand}</TableCell>
            <TableCell align="center">{rowData.color}</TableCell>
            <TableCell align="center">{rowData.type}</TableCell>
            <TableCell align="center">
                {`${data.primary_price} ${currencySymbol[PLN]}`}
            </TableCell>
            <TableCell align="center">
                {`${data.promotional_price} ${currencySymbol[PLN]}`}
            </TableCell>
            {currencyVisible && (
                <TableCell align="center" className={classes.featured}>
                    {data.euro}
                    &nbsp;
                    {data.currency === EUR && currencySymbol[EUR]}
                </TableCell>
            )}
            <TableCell align="center">
                <Box display="flex" width="150" justifyContent="center">
                    <TextField
                        value={data.discount.percent}
                        className={classes.discount}
                        placeholder="%"
                        type="number"
                        disabled={!!data.discount.currValue}
                        onChange={(e) =>
                            handleChangeDiscount(e.target.value, 'percent')
                        }
                    />
                    <TextField
                        value={data.discount.currValue}
                        className={classes.discount}
                        placeholder={currencySymbol[PLN]}
                        type="number"
                        disabled={!!data.discount.percent}
                        onChange={(e) =>
                            handleChangeDiscount(e.target.value, 'currValue')
                        }
                    />
                </Box>
            </TableCell>
            {currencyVisible && (
                <TableCell align="center">
                    <Select
                        value={data.currency}
                        onChange={(e) => handleChangeCurrency(e.target.value)}
                    >
                        <MenuItem value={PLN}>{PLN}</MenuItem>
                        <MenuItem value={EUR}>{EUR}</MenuItem>
                    </Select>
                </TableCell>
            )}
            {shopFromVisible && (
                <TableCell align="center">
                    <Select
                        value={data.shopFromId}
                        className={clsx(
                            data.shopFromId === user.shopId && classes.featured,
                            classes.shopsSelect
                        )}
                        onChange={(e) =>
                            setData({ ...data, shopFromId: e.target.value })
                        }
                    >
                        {shopsInShopFromSelect.map((shop) => (
                            <MenuItem
                                className={clsx(
                                    user.shopId === shop.id && classes.featured
                                )}
                                key={shop.id}
                                value={shop.id}
                            >
                                {shop.name}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
            )}
            <TableCell align="right">
                <TextField
                    placeholder="rozmiar"
                    type="number"
                    onKeyDown={(e) => handleSubmitEnter(e, data, rowData)}
                    onChange={(e) => setData({ ...data, size: e.target.value })}
                    value={data.size}
                />
            </TableCell>
            {(viewType === 'SALE_OF_SOCKS_AND_INSOLES') && (
                <TableCell align="right">
                    <TextField
                        placeholder="ilość"
                        type="number"
                        onChange={(e) => setData({ ...data, quantity: e.target.value })}
                        value={data.quantity}
                    /> 
                </TableCell>
            )}

            <TableCell align="right">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickRow(data, rowData)}
                >
                    {currencyVisible ? 'Sprzedano!' : 'Dodaj'}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default memo<Props>(
    SaleTableRow,
    (prev: Order, next: Order) => isEqual(prev, next)
);
