import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import styled from 'styled-components/macro';
import * as text from 'constants/strings';
import useIsGranted from 'hooks/useIsGranted';
import { useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import { selectShops } from 'context/features/global/globalSlice';
import { findShopById } from 'helpers/global';

const useStyles = makeStyles((theme) => ({
    plusBtn: {
        padding: '0px 0px',
    },
    workerBox: {
        minHeight: 24,
    },
    stickyCol: {
        [theme.breakpoints.down('md')]: {
            position: 'sticky',
            left: '0',
            zIndex: '1',
            background: '#fff',
        },
    },

    weekDay: {
        [theme.breakpoints.down('xs')]: {
            float: 'left',
            width: '240px',
            position: 'sticky',
            left: '0',
            zIndex: '1',
            background: '#fff',
        },
    },

    innerOne: {
        float: 'left',
    },
    innerTwo: {
        float: 'left',
        clear: 'left',
        fontSize: '10px',
    },
    loggedTime: {
        float: 'right',
        marginLeft: '5px',
    },
    joinedHours: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '5px',
    },
}));

const Block = styled.div`
    color: ${({ color }) => color || 'inherit'};
`;

const GraphicWorkTimesBlock = ({ userTimeSheet }) => {
    const classes = useStyles();
    const isGranted = useIsGranted();
    const user = useSelector((state) => selectUser(state));
    const shops = useSelector((state) => selectShops(state));
    const usersShop = findShopById(shops, user.shopId);

    const hasPermissions = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
    ]);
    const hasSameShopAsDirector =
        isGranted.hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]) &&
        userTimeSheet &&
        usersShop &&
        userTimeSheet?.type === usersShop.name;
    const isAllowedToEditForDirector =
        isGranted.hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]) &&
        (userTimeSheet?.type === text.WAREHOUSE ||
            userTimeSheet?.type === text.OFFICE ||
            userTimeSheet?.type === text.TRAINING);

    const additionalPermsForPrzejscieDirector =
        isGranted.hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]) &&
        userTimeSheet &&
        usersShop &&
        usersShop.name === text.shopName.PRZEJSCIE &&
        userTimeSheet?.type === text.shopName.RYNEK;

    const getTime = (date) => (date ? moment(date).format('HH:mm:ss') : '');

    const getColor = () => {
        if (
            !userTimeSheet?.type ||
            ['Krupówki', 'Poraj', 'Przejście', 'Pod tel. '].includes(
                userTimeSheet?.type
            )
        ) {
            return '#000';
        }

        return '#fff';
    };

    const startDate = getTime(userTimeSheet?.startDate);
    const endDate = getTime(userTimeSheet?.endDate);
    const startDateWarehouse = getTime(userTimeSheet?.startDateWarehouse);
    const endDateWarehouse = getTime(userTimeSheet?.endDateWarehouse);

    const getSubstractedTimes = () => {
        const roundDownTo15Minutes = (minutes) => Math.floor(minutes / 15) * 15;

        const sub = moment
            .duration(endDate, 'HH:mm')
            .subtract(moment.duration(startDate, 'HH:mm'));
        const h = sub.hours();
        const m = roundDownTo15Minutes(sub.minutes());

        const subWh = moment
            .duration(endDateWarehouse, 'HH:mm')
            .subtract(moment.duration(startDateWarehouse, 'HH:mm'));
        const hWh = subWh.hours();
        const mWh = roundDownTo15Minutes(subWh.minutes());

        if (h <= 0 && m <= 0) {
            return <>&nbsp;</>;
        }

        if (hWh <= 0 && mWh <= 0) {
            return `${h}h ${m}m`;
        }

        return `${h}h ${m}m + ${hWh}h ${mWh}m`;
    };

    return (
        <Block color={getColor()}>
            <div className={classes.innerOne}>
                {userTimeSheet?.type}
                {(hasPermissions ||
                    hasSameShopAsDirector ||
                    isAllowedToEditForDirector ||
                    additionalPermsForPrzejscieDirector) && (
                    <div className={classes.loggedTime}>
                        {getSubstractedTimes()}
                    </div>
                )}
            </div>
            <div className={classes.joinedHours}>
                {(hasPermissions ||
                    hasSameShopAsDirector ||
                    isAllowedToEditForDirector ||
                    additionalPermsForPrzejscieDirector) && (
                    <div>
                        <div className={classes.innerTwo}>{startDate}</div>
                        <div className={classes.innerTwo}>{endDate}</div>
                    </div>
                )}
                {(hasPermissions ||
                    hasSameShopAsDirector ||
                    isAllowedToEditForDirector ||
                    additionalPermsForPrzejscieDirector) &&
                    startDateWarehouse &&
                    endDateWarehouse && (
                        <div>
                            <div className={classes.innerTwo}>
                                (M){startDateWarehouse}
                            </div>
                            <div className={classes.innerTwo}>
                                (M){endDateWarehouse}
                            </div>
                        </div>
                    )}
            </div>
        </Block>
    );
};

export default GraphicWorkTimesBlock;
