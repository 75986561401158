// @flow
import React, { useEffect, memo, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import {
    makeStyles,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { useDispatch } from 'react-redux';
import {
    addAlert,
    setMessageCounter,
    removeMessageCounter,
    setIsLoading,
} from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import { logoutUser } from 'context/features/user/userSlice';

const useStyles = makeStyles((theme) => ({
    alert: {
        margin: '3px 0px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 0 0 10px',
        [theme.breakpoints.down('sm')]: {
            '& .MuiAlert-icon': {
                display: 'none',
            },
            flexWrap: 'wrap',
            padding: '0 3px 0 10px',
        },
    },
    message: {
        wordBreak: 'break-all',
        fontSize: 'initial',
    },
    checkText: {
        minWidth: '225px',
        [theme.breakpoints.down('sm')]: {
            minWidth: 'inherit',
            margin: '0 1rem 0 0',
            fontSize: 'inherit',
        },
    },
    container: {
        maxHeight: '8.5rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '11rem',
        },
    }
}));

const Alerts = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [userMessages, setUserMessages] = useState([]);
    const [intervalId, setIntervalId] = useState(null);

    const getAlerts = async () => {
        try {
            const response = await sendAuthAjax('get', `/users/messages`);
            const { unSeenCount, messages } = response.data;

            dispatch(setMessageCounter(unSeenCount));
            setUserMessages(messages);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logoutUser());
            }
        }
    };

    const hasSeen = async (messageId) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('put', `/messages/seen/${messageId}`);
            setUserMessages(userMessages.filter((mes) => mes.id !== messageId));
            dispatch(removeMessageCounter());
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const start = () => {
        getAlerts();
        const intId = setInterval(getAlerts, 500000);
        setIntervalId(intId);
    };

    const stop = () => {
        clearInterval(intervalId);
    };

    const sort = (x, y) => {
        return (
            moment(y.created_at).format('X') - moment(x.created_at).format('X')
        );
    };

    useEffect(() => {
        stop();
        start();
    }, []);

    if (userMessages.length > 0) {
        return (
            <ViewContentCard title={text.MESSAGES}>
                <div className={classes.container}>
                    {userMessages.sort(sort).map((message) => (
                            <Alert
                                color="warning"
                                severity="warning"
                                className={classes.alert}
                                key={message.id}
                                action={
                                    <FormControlLabel
                                        className={classes.checkText}
                                        control={
                                            <Checkbox
                                                onChange={() => hasSeen(message.id)}
                                                color="primary"
                                            />
                                        }
                                        label="Oznacz jako przeczytane"
                                    />
                                }
                            >
                                <Typography
                                    className={classes.message}
                                    variant="h6"
                                    component="span"
                                >
                                    {message.content}
                                </Typography>
                                <Typography 
                                        variant="body2" 
                                        component="span"
                                >
                                    {` - od ${message.author_name}`}
                                </Typography>
                            </Alert>
                    ))}
                </div>
            </ViewContentCard>
        );
    }

    return ('');
};

export default memo(Alerts);
