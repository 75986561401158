// @flow
import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    TextField,
    Button,
    Switch,
} from '@material-ui/core';
import { formatPrice } from 'helpers/global';

type Props = {
    row: any,
    handleClick: Function,
};

const ReleaseTableRow = ({ row, handleClick }: Props) => {
    const [data, setData] = useState({ sizeTo: '', info: '' });
    const [checked, setChecked] = useState(false);

    const handleSubmitEnter = (e, row, data) => {
        if (e.key === 'Enter') {
            handleClick(row, data);
        }
    };

    return (
        <TableRow hover key={row.id}>
            <TableCell align="center">{row.code}</TableCell>
            <TableCell align="center">{row.brand}</TableCell>
            <TableCell align="center">{row.color}</TableCell>
            <TableCell align="center">{row.type}</TableCell>
            <TableCell align="center">{formatPrice(row.primary_price)}</TableCell>
            <TableCell align="center">{formatPrice(row.promotional_price)}</TableCell>
            <TableCell align="center">
                <TextField
                    label={checked ? 'info' : 'imię nazwisko'}
                    value={data.info}
                    onChange={(e) => setData({ ...data, info: e.target.value })}
                />
                <Switch
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    name="checkedA"
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    label="rozmiar"
                    type="number"
                    value={data.sizeTo}
                    onKeyDown={(e) => handleSubmitEnter(e, row.id, data)}
                    onChange={(e) =>
                        setData({ ...data, sizeTo: e.target.value })
                    }
                />
            </TableCell>
            <TableCell align="center">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleClick(row.id, data)}
                >
                    Wydaj
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default ReleaseTableRow;
